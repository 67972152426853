import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
//import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { PrismicProvider } from '@prismicio/react'

//import { repositoryConfigs } from './prismicPreviews'
//import { linkResolver } from './linkResolver'
import CustomLink from './CustomLink'
import externalLinkComponent from '../utils/externalLinkComponent'

import '../styles/global.css'

const GatsbyLinkShim = ({ href, ...props }) => (
    <GatsbyLink to={href} {...props} />
)

export const wrapRootElement = ({ element }) => (
    <PrismicProvider
        externalLinkComponent={externalLinkComponent}
        //linkResolver={CustomLink}
        //internalLinkComponent={GatsbyLinkShim}
    >
        {element}
        {/* <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
            {element}
        </PrismicPreviewProvider> */}
    </PrismicProvider>
)
