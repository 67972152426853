exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-emissions-series-js": () => import("./../../../src/pages/emissions-series.js" /* webpackChunkName: "component---src-pages-emissions-series-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programme-tv-js": () => import("./../../../src/pages/programme-tv.js" /* webpackChunkName: "component---src-pages-programme-tv-js" */),
  "component---src-pages-replay-tv-js": () => import("./../../../src/pages/replay-tv.js" /* webpackChunkName: "component---src-pages-replay-tv-js" */),
  "component---src-templates-blog-category-page-js": () => import("./../../../src/templates/blog-category-page.js" /* webpackChunkName: "component---src-templates-blog-category-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-channel-programs-js": () => import("./../../../src/templates/channel-programs.js" /* webpackChunkName: "component---src-templates-channel-programs-js" */),
  "component---src-templates-live-channel-js": () => import("./../../../src/templates/live-channel.js" /* webpackChunkName: "component---src-templates-live-channel-js" */),
  "component---src-templates-live-stream-js": () => import("./../../../src/templates/live-stream.js" /* webpackChunkName: "component---src-templates-live-stream-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-replay-channel-js": () => import("./../../../src/templates/replay-channel.js" /* webpackChunkName: "component---src-templates-replay-channel-js" */),
  "component---src-templates-show-js": () => import("./../../../src/templates/show.js" /* webpackChunkName: "component---src-templates-show-js" */)
}

