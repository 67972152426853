exports.linkResolver = function linkResolver(doc) {
    const pages = ['page', 'replay_page', 'blog_page']

    // URL for a page type
    if (pages.includes(doc.type)) {
        return `/${doc.uid}`
    }

    // URL for a post type
    if (doc.type === 'post') {
        return `/blog/${doc.uid}` // temp. solution
    }

    // URL for shows page
    if (doc.type === 'shows_page') {
        return `/emissions-series`
    }

    // Backup for all other types
    return '/'
}
