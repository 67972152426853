import * as React from 'react'
import { Link } from 'gatsby'

const externalLinkComponent = ({ href, ...props }) => {
    const url = new URL(href)
    const prodHost = 'www.ledirect.fr' // temp. fix (process.env.PROD_HOST)

    if (url.host === prodHost) {
        return <Link to={url.pathname} {...props} />
    } else {
        return (
            <a href={href} {...props}>
                {props.children}
            </a>
        )
    }
}

export default externalLinkComponent
