import * as React from 'react'
import { Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'

const CustomLink = ({ className, activeClassName, element, content }) => {
    if (element.link_type === 'Document') {
        return (
            <Link
                className={className}
                activeClassName={activeClassName}
                to={linkResolver(element)}
                key={element.id}
                //title={content}
            >
                {content}
            </Link>
        )
    }

    if (element.link_type === 'Web') {
        const relAttr = element.target ? 'noopener' : ''

        return (
            <a
                className={className}
                href={element.url}
                target={element.target}
                rel={relAttr}
                //title={content}
            >
                {content}
            </a>
        )
    }

    return null
}

export default CustomLink
